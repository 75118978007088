import $ from 'jquery'
import AOS from 'aos'
import barba from '@barba/core';
import {gsap} from "gsap";
import initSliders from "./sliders";

export const initAnimations = () => {

    if (window.innerWidth > 1300) {
        barba.init({
            prevent: ({el}) => el.classList && el.classList.contains('prevent'),
            transitions: [{
                name: 'opacity-transition',
                async leave(data) {
                    await leaveAnim(data.current.container)
                },
                async enter(data) {
                    window.scrollTo({top: 0});
                    await enterAnim(data.next.container)
                },
                async after(data) {
                    initAOS();
                    initSliders();
                    scrollToAnchor()
                }
            }]
        });
    }

    initAOS()

}

function initAOS() {
    AOS.init({
        offset: 50,
        duration: 800,
        delay: 50,
        once: true,
        disable: 'mobile'
    });
    // refresh AOS after page transition in case scrolling is not updated
    setTimeout(() => {
        AOS.refresh();
    }, 1000);
}


function leaveAnim(container) {
    var tl = gsap.timeline({});
    return tl
        .to(".fence", {
            duration: 0.2,
            x: '-15vw',
            ease: "ease-in-out",
            paused: false
        })
        .to(container, {
            opacity: 0,
            duration: 0.3
        });
}

function enterAnim(container) {
    var tl = gsap.timeline({});
    return tl
        .to(".fence", {
            duration: 0.2,
            x: '-150vw',
            ease: "ease-in-out",
            paused: false
        })
        .from(container, {
            opacity: 0,
            duration: 0.3,
        });
}

function scrollToAnchor() {
    if (window.location.hash) {
        // Scroll to the anchor
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - 120
        }, 400); // Adjust the scroll speed as needed
    }
}

export default initAnimations