export const initUtilities = () => {

    // FAQ Questions
    $(document).on('click', '.faq-question', function () {
        $(this).toggleClass('open');
        $(this).next().slideToggle(200);
    });

    // Location Tabs
    $(document).on('click', '.tab-link', function () {
        let location = $(this).data('location');
        $('.tab-link, .detail').removeClass('is-active');
        $(this).addClass('is-active');
        $('#'+location).addClass('is-active');
    })

    // Map view toggle
    $(document).on('click', '.icon-button', function () {
        $('#list-view, #map-view, .white-gradient').hide();
        $('.icon-button').removeClass('is-active');
        $(this).addClass('is-active');
        let view = $(this).data('view')
        $('#'+view).show();
        if(view === 'map-view'){
            $('.white-gradient').show();
        }
    })

    // province clicks
    $(document).on('click', '.province-click', function () {
        let province = $(this).data('province');
        let province_label = $(this).data('province-label');
        $('.province-location').hide();
        $('#location-panel .province-label').text(province_label);
        $('#province-' + province + ', #location-panel').fadeIn(200);
    });

    $(document).on('click', '#close-location', function () {
        $('.province-location, #location-panel').hide();
    })

}
export default initUtilities;